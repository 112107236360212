//
// frappe-gantt.scss
//

.gantt {
    .bar-progress {
        fill: $primary;
    }
    .bar-wrapper:hover {
        .bar-progress {
            fill: lighten($primary, 5%);
        }
    }
    .bar, .bar-wrapper:hover .bar {
        fill: $progress-bg;
    }
    .bar-label, .bar-label.big {
        fill: #{map-get($grays, "700")};
    }
    .grid-header {
        fill: $table-hover-bg;
        stroke: $table-hover-bg;
    }
    .grid-row {
        fill: transparent;
        &:nth-child(even) {
            fill: $table-hover-bg;
        }
    }
    .lower-text, .upper-text {
        fill:  $table-color;
    }
    .row-line {
        stroke: $table-border-color;
    }
    .today-highlight {
        fill: #{map-get($grays, "200")};
    }
}

.gantt-container {
    .popup-wrapper {
        .pointer {
            display: none;
        }
    }
}



// Dark mode
body[data-layout-color="dark"] {
    .gantt {
        .bar-label, .bar-label.big {
            fill: #{map-get($dark-grays, "700")};
        }
        .today-highlight {
            fill: #{map-get($dark-grays, "300")};
        }
    }
}
